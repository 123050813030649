import './css/bootstrap.min.css';
import './css/owl.carousel.min.css';
import './css/owl.theme.default.min.css';
import './css/style.css';
import './css/responsive.css';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import routes from '~/config/routes';
import { useWindowDimensions } from '~/hooks';

function Footer({ logo, sticky_logo, showStickey, setShowStickey, iconFooter = [] }) {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const REACT_APP_PREFIX = process.env.REACT_APP_PREFIX;

    const { width } = useWindowDimensions();

    return (
        <>
            <footer>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-5 col-sm-7 col-8 left"></div>
                        {width > 600 && <div className="col-lg-6 col-md-7 right"></div>}

                        <div className={`container row ${width < 600 ? 'mt-5' : ''}`} style={{ zIndex: 3 }}>
                            <div className="col-lg-6 col-md-4 col-sm-6 col-7">
                                <img
                                    src={require('~/assets/images/projectsupportstation/images/Gig_Gik_Logo_Chin.png')}
                                    alt=""
                                    className="logo_footer mb-5"
                                />
                                <div className="footer-text">
                                    <p className="mb-3">
                                        {t('projectSupportStation.telephone')}{' '}
                                        <a href="tel:+852 5606 1150">+852 5606 1150</a>
                                    </p>
                                    <p className="mb-3">
                                        {t('projectSupportStation.email')}{' '}
                                        <a href="mailto:info@giggik.com">info@giggik.com</a>
                                    </p>
                                    <div className="row">
                                        <div className="w-auto" style={{ paddingRight: 0 }}>
                                            {`${t('projectSupportStation.address')}`}
                                        </div>
                                        <a
                                            className="col-9"
                                            href="https://www.google.com.hk/maps/search/%E5%9C%B0%E5%9D%80:+%E4%B9%9D%E9%BE%8D%E7%81%A3%E5%AE%8F%E7%85%A7%E9%81%9333%E8%99%9F+%E5%9C%8B%E9%9A%9B%E4%BA%A4%E6%98%93%E4%B8%AD%E5%BF%836%E6%A8%93602,03,05%E5%AE%A4/@22.3211051,114.2068482,17z/data=!3m1!4b1?hl=zh-TW&entry=ttu"
                                            target="_blank"
                                            style={{ paddingLeft: '5px' }}
                                        >
                                            {`九龍灣宏照道33號 國際交易中心6樓602,03,05室`}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className={`col-lg-6 col-md-7 ${width < 600 ? 'mt-5' : 'right'}`}>
                                <ul
                                    className={`col-mb-12 mb-5 ${width < 600 ? 'mt-5' : ''}`}
                                    style={{ marginLeft: width < 600 ? '-20px' : 0 }}
                                >
                                    <li>
                                        <a
                                            href={`${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/${
                                                i18n.language
                                            }${routes.projectSupportStation}`}
                                        >
                                            {t('projectSupportStation.supportStation')}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={`${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/${
                                                i18n.language
                                            }${routes.xiaoHongShu}`}
                                        >
                                            {t('projectSupportStation.littleRedbook')}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.giggik.com/recruitment" target="_blank">
                                            {t('projectSupportStation.recruitmentStation')}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={`${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/${
                                                i18n.language
                                            }${routes.humanResourceStation}`}
                                        >
                                            {t('projectSupportStation.talentStation')}
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a
                                            href={`${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/${
                                                i18n.language
                                            }${routes.bud}`}
                                        >
                                            {t('projectSupportStation.BUD')}
                                        </a>
                                    </li> */}
                                </ul>
                                <div className="col-mb-12 social mb-3">
                                    {iconFooter?.map((value, index) => {
                                        return (
                                            <a href={value?.href} target="_blank" className="me-5" key={String(index)}>
                                                <img src={value?.icon} alt={value?.title} />
                                            </a>
                                        );
                                    })}
                                </div>
                                <div className="clearfix"></div>
                                <span className="copyright bold-500">
                                    {' '}
                                    {t('projectSupportStation.copyright')}
                                    <br />
                                    {t('projectSupportStation.licenseNumber')}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <div className="container_logo" style={{ zIndex: sticky_logo ? 10 : 1 }}>
                {sticky_logo ? (
                    <img
                        src={logo?.image}
                        alt={logo?.title}
                        style={{ cursor: 'pointer' }}
                        onClick={setShowStickey?.bind(this, !showStickey)}
                    />
                ) : (
                    <a href={logo?.hyper_link} target="_blank">
                        <img src={logo?.image} alt={logo?.title} />
                    </a>
                )}
                {sticky_logo && (
                    <div
                        className="bg-white collapse_container_logo"
                        style={{ display: showStickey ? 'flex' : 'none' }}
                    >
                        <div className="d-flex flex-column spacing-0 w-auto pt-3">
                            {sticky_logo?.map((value, index) => {
                                if (index > 0) {
                                    return (
                                        <a
                                            href={value?.hyper_link}
                                            target={value?.new_tab ? '_blank' : '_parent'}
                                            className="mb-3"
                                            key={String(index)}
                                        >
                                            <img src={value?.image} alt={value?.title} />
                                        </a>
                                    );
                                }

                                return null;
                            })}
                        </div>
                    </div>
                )}
            </div>

            <div className="back_to_top">
                <a href="#">
                    <img src={require('~/assets/images/projectsupportstation/images/back_to_top.png')} />
                </a>
                <a
                    href={
                        iconFooter.find((state) => state?.title?.toLocaleUpperCase() == 'WhatApps'?.toLocaleUpperCase())
                            ?.href
                    }
                    target="_blank"
                >
                    <img src={require('~/assets/images/projectsupportstation/images/WhatsApp_icon.png')} />
                </a>
            </div>
        </>
    );
}

export default Footer;
