import { useEffect, useLayoutEffect, useState } from 'react';
import '../ProjectSupportStation/css/bootstrap.min.css';
import '../ProjectSupportStation/css/owl.carousel.min.css';
import '../ProjectSupportStation/css/owl.theme.default.min.css';
import '../ProjectSupportStation/css/style.css';
import '../ProjectSupportStation/css/responsive.css';
import { useTranslation } from 'react-i18next';
import Header from '../ProjectSupportStation/Header';
import Footer from '../ProjectSupportStation/Footer';
import routes from '~/config/routes';
import * as Services from '~/services/projectSupportStationService';
import { useWindowDimensions } from '~/hooks';
import { Helmet } from 'react-helmet';

const XiaoHongShu = () => {
    const { t, i18n } = useTranslation();
    const REACT_APP_PREFIX = process.env.REACT_APP_PREFIX;

    // const { height, width } = useWindowDimensions();

    const _addScript = (source) => {
        let scripts = document.getElementsByTagName('script');
        for (var i = scripts.length; i--; ) {
            if (
                scripts[i].src == `http://${window.location.host}${source}` ||
                scripts[i].src == `https://${window.location.host}${source}`
            )
                return;
        }

        const script = document.createElement('script');

        script.src = process.env.PUBLIC_URL + source;
        script.async = true;

        document.body.appendChild(script);
    };

    const [optionIndex, setOptionIndex] = useState(0);
    const [userIndex, setUserIndex] = useState(0);
    const [data, setData] = useState(null);
    const [plan, setPlan] = useState([]);
    const [menu, setMenu] = useState([]);
    const [iconFooter, setIconFooter] = useState([]);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // windowHeight
    useLayoutEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [width, height]);

    // const menu = [
    //     {
    //         title: t('xiaoHongShu.littleRedbook'),
    //         href: '#xiao-hong-shu',
    //     },
    //     {
    //         title: t('xiaoHongShu.userStructure'),
    //         href: '#users-distrubutions',
    //     },
    //     {
    //         title: t('xiaoHongShu.userEcology'),
    //         href: '#users-behaviours',
    //     },
    //     {
    //         title: t('xiaoHongShu.extensionServices'),
    //         href: '#promotional-services',
    //     },
    //     {
    //         title: t('xiaoHongShu.whySeekProfessionalSupport'),
    //         href: '#reson-for-seeking-gig-gik-service',
    //     },
    //     {
    //         title: t('xiaoHongShu.whyXiaohongshuIsBetter'),
    //         href: '#why-gig-gik-service-better',
    //     },
    //     {
    //         title: t('xiaoHongShu.monthlyFeePackage'),
    //         href: '#plans',
    //     },
    //     {
    //         title: t('xiaoHongShu.userCases'),
    //         href: '#successful-cases',
    //     },
    //     {
    //         title: t('xiaoHongShu.contactUs'),
    //         href: '#contact',
    //     },
    //     {
    //         title: t('xiaoHongShu.commonProblem'),
    //         href: '#faq',
    //     },
    // ];

    const logo = {
        image: require('~/assets/images/projectsupportstation/images/xiao-hong-shu.png'),
        content: [
            {
                title: t('logoName.home'),
                href: `${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/${i18n.language}${routes.home}`,
                image: require('~/assets/images/projectsupportstation/images/home.png'),
            },
            {
                title: t('logoName.supportStation'),
                href: `${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/${i18n.language}${
                    routes.projectSupportStation
                }`,
                image: require('~/assets/images/projectsupportstation/images/support-station.png'),
            },
            {
                title: t('logoName.talentStation'),
                image: require('~/assets/images/projectsupportstation/images/human-resource.png'),
                href: `${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/${i18n.language}${
                    routes.humanResourceStation
                }`,
            },
            // {
            //     title: t('logoName.budFund'),
            //     image: require('~/assets/images/projectsupportstation/images/bud.png'),
            //     href: `${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/${i18n.language}${routes.bud}`,
            // },
        ],
    };

    _addScript('/js/owl-carousel/owl.carousel.min.js');

    useEffect(() => {
        if (data) {
            _addScript('/js/custom.js');
            _addScript('/js/bootstrap.bundle.min.js');
        }
    }, [data]);

    useEffect(() => {
        Services.getXiaoHungShu({ language: i18n.language }).then((res) => {
            setData(res);
            setPlan(res?.plan);

            const menu = res?.menu
                ?.sort((a, b) => {
                    return a?.ordering - b?.ordering;
                })
                ?.map((value) => {
                    return {
                        title: value?.menu_text,
                        href: `#xiao-hong-shu-${value?.ordering}`,
                        name: value?.section_name,
                        id: `xiao-hong-shu-${value?.ordering}`,
                    };
                });

            setMenu(menu);

            const iconFooter = res?.footer?.map((value) => {
                let image = require('~/assets/images/projectsupportstation/images/whatsapp.png');
                if (value?.name?.toLocaleUpperCase() == 'WhatApps'?.toLocaleUpperCase()) {
                    image = require('~/assets/images/projectsupportstation/images/whatsapp.png');
                }
                if (value?.name?.toLocaleUpperCase() == 'Facebook'?.toLocaleUpperCase()) {
                    image = require('~/assets/images/projectsupportstation/images/facebook.png');
                }
                if (value?.name?.toLocaleUpperCase() == 'Instagram'?.toLocaleUpperCase()) {
                    image = require('~/assets/images/projectsupportstation/images/instagram.png');
                }
                if (value?.name?.toLocaleUpperCase() == 'Linkedin'?.toLocaleUpperCase()) {
                    image = require('~/assets/images/projectsupportstation/images/linkedin.png');
                }

                return {
                    icon: image,
                    href: value?.url,
                    title: value?.name,
                };
            });

            setIconFooter(iconFooter);
        });
    }, []);

    const _onMouseEvent = ({ index, over = false }) => {
        if (width > 767) {
            const newPlane = plan.map((value, i) => {
                if (over == true && i == index) {
                    return { ...value, over: true };
                } else {
                    if (over == false) {
                        return { ...value, over: null };
                    }
                    return { ...value, over: false };
                }
            });

            setPlan(newPlane);
        }
    };

    return (
        <>
            <Helmet>
                <title>小紅書推廣｜KOC種草｜企業號代運營 - 職極GigGik</title>
                <meta name="og:title" content="小紅書推廣｜KOC種草｜企業號代運營 - 職極GigGik" />
                <meta name="og:url" content={`https://www.giggik.com/tc${routes.xiaoHongShu}`} />
                <meta
                    name="description"
                    content="小紅書推廣，官方藍剔認證，KOL種草探店，立即獲取報價。團隊具16+年中國網絡營銷經驗，熟悉小紅書生態，坐擁龐大網紅港漂合作網絡"
                />
                <link rel="canonical" href={`https://www.giggik.com/tc${routes.xiaoHongShu}`} />
                <style>{`
                    h2 {
                        font-size: 36px;
                        font-weight: 700;
                    }
                    .font-size-18 {
                        font-size: 1.75rem;
                        font-weight: 500;
                        line-height: 1.2;
                        font-family: Noto Sans TC, serif;
                    }
                `}</style>
            </Helmet>
            {data && (
                <div className="wrapper">
                    <Header menu={menu} logo={logo} page={routes.xiaoHongShu} />

                    <div className="banner">
                        <div className="container-fluid wave wave_banner spacing-0">
                            <div className="row container-fluid spacing-0">
                                <div className="col-12 spacing-0">
                                    <div className="banner-items owl-carousel owl-theme container-fluid spacing-0">
                                        {data?.banner?.map((value, index) => {
                                            return (
                                                <div
                                                    className={`item spacing-0 ${
                                                        value.media_type == 'image' ? '' : 'video'
                                                    }`}
                                                    key={String(index)}
                                                >
                                                    {value.media_type == 'image' ? (
                                                        <img src={value?.url} alt={value?.title} />
                                                    ) : (
                                                        <video
                                                            className="videoContent"
                                                            width="100%"
                                                            height="100%"
                                                            style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0,
                                                                objectFit: 'cover',
                                                            }}
                                                            // autoPlay={index == 0 ? true : false}
                                                            // playsInline
                                                            // muted="muted"
                                                            controls
                                                        >
                                                            <source src={value?.url} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="bg-cream mb-5" id={menu[0]?.id}>
                        <div className="container text-center d-flex justify-content-center ">
                            <div className="row align-items-center">
                                <div className="col-md-6 mt-5 mb-5 text-center d-flex flex-column align-items-center">
                                    <h1 className="mb-4 color-red mt-5 col-12" style={{ textAlign: 'start' }}>
                                        {menu[0]?.name}
                                    </h1>
                                    <img
                                        src={require('~/assets/images/projectsupportstation/images/Gig_Gik_Social.png')}
                                    />
                                </div>
                                <div className="col-md-6 text-center d-flex flex-column align-items-center mt-5 mb-5 intro-padding-start">
                                    <img
                                        src={require('~/assets/images/projectsupportstation/images/Gig_Gik_Book.png')}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="service" id={menu[1]?.id}>
                        <div className="container-fluid wave wave_top">
                            {/* <div className="row"> */}
                            <div className="col-12 text-center">
                                <h2 className="mb-4 color-red">{menu[1]?.name}</h2>
                            </div>
                            {/* </div> */}
                        </div>

                        <div className="container">
                            <div className="d-flex col-12 pt-5 flex-column align-items-center">
                                <div style={{ width: '15%', zIndex: 3 }}>
                                    <img
                                        src={require('~/assets/images/projectsupportstation/images/GigGik_Heart.png')}
                                    />
                                </div>
                                <img
                                    src={require('~/assets/images/projectsupportstation/images/bg_red.png')}
                                    style={{ marginTop: '-3vw' }}
                                />
                            </div>

                            <div className="d-flex col-10 offset-1 pt-5 flex-column align-items-center">
                                <img src={require('~/assets/images/projectsupportstation/images/bg_orange.png')} />
                            </div>

                            <div className="d-flex col-8 offset-2 pt-5 flex-column align-items-center">
                                <img src={require('~/assets/images/projectsupportstation/images/bg_yellow.png')} />
                            </div>

                            <div className="col-12 row mt-5 users_distrubutions">
                                {data['users_distrubutions']?.map((value, index) => {
                                    return (
                                        <div className="col-md-4 mb-5" key={String(index)}>
                                            <p style={{ paddingBottom: 0 }} className="color-orange mb-3 font-size-18">
                                                {value?.title}
                                            </p>
                                            <ul className="check-circle">
                                                {value?.content?.map((content, iContent) => {
                                                    return (
                                                        <li
                                                            className={`${content?.color} mb-3 bold-500`}
                                                            key={String(iContent)}
                                                        >
                                                            {content?.content}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </section>

                    <section className="benefits bg-cream" id={menu[2]?.id}>
                        <div className="container-fluid wave wave_top_cream">
                            {/* <div className="row"> */}
                            <div className="col-12 text-center">
                                <h2 className="mb-4 color-red">{menu[2]?.name}</h2>
                            </div>
                            {/* </div> */}
                        </div>

                        <div className="container mb-5">
                            <div className="col-12 row">
                                {data['users_behaviours']?.map((value, index) => {
                                    return (
                                        <div className="col-md-3" key={String(index)}>
                                            <div className="row h-auto">
                                                <div className="col-md-10">
                                                    <img src={value?.image} />
                                                </div>
                                                <div className="col-2 icon-next justify-content-center align-items-center">
                                                    {index < data['users_behaviours']?.length - 1 && (
                                                        <img
                                                            src={require('~/assets/images/projectsupportstation/images/arrow-forward.png')}
                                                            style={{ width: '20px', height: 'auto' }}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            <div className=" d-flex flex-column align-items-center">
                                                <ul className="check-circle mt-5">
                                                    {value?.content?.map((content, iContent) => {
                                                        return (
                                                            <li
                                                                className={`${content?.color} mb-3 bold-500`}
                                                                key={String(iContent)}
                                                            >
                                                                {content?.content}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </section>

                    <section className="service" id={menu[3]?.id}>
                        <div className="container-fluid wave wave_top">
                            {/* <div className="row"> */}
                            <div className="col-12 text-center">
                                <h2 className="mb-4 color-red">{menu[3]?.name}</h2>
                            </div>
                            {/* </div> */}
                        </div>

                        <div className="row container ">
                            <div className="col-12">
                                <nav>
                                    <div
                                        className="nav nav-tabs justify-content-center mb-5"
                                        id="nav-tab"
                                        role="tablist"
                                    >
                                        {data['promotional_services']?.map((value, index) => {
                                            return (
                                                <button
                                                    key={String(index)}
                                                    className={`nav-link ${index == optionIndex ? 'active' : ''}`}
                                                    id={`nav-tab-${index}`}
                                                    data-bs-toggle="tab"
                                                    data-bs-target={`#nav-${index}`}
                                                    type="button"
                                                    role="tab"
                                                    aria-controls={`#nav-${index}`}
                                                    aria-selected={index == optionIndex}
                                                    onClick={setOptionIndex?.bind(this, index)}
                                                >
                                                    {value?.catrgory}
                                                </button>
                                            );
                                        })}
                                    </div>
                                </nav>

                                <div className="tab-content ps-5 pe-5" id="nav-tabContent">
                                    {data['promotional_services']?.map((value, index) => {
                                        return (
                                            <div
                                                key={String(index)}
                                                className={`tab-pane fade ${optionIndex == index ? 'active show' : ''}`}
                                                id={`nav-${index}`}
                                                role="tabpanel"
                                                aria-labelledby={`nav-tab-${index}`}
                                            >
                                                <div className="row">
                                                    <div className="col-md-6 pb-3 pe-3">
                                                        <ul className="check-circle">
                                                            {value?.content?.map((content, iContent) => {
                                                                return (
                                                                    <li
                                                                        className={`${content?.color} mb-3 bold-500`}
                                                                        key={String(iContent)}
                                                                    >
                                                                        {content?.content}
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-6 pb-3">
                                                        <img src={value?.image} alt={value?.catrgory} />
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="benefits bg-cream" id={menu[4]?.id}>
                        <div className="container-fluid wave wave_top_cream">
                            {/* <div className="row"> */}
                            <div className={`col-12 text-center`}>
                                <h2 className="mb-4 color-red">{menu[4]?.name}</h2>
                            </div>
                            {/* </div> */}
                        </div>

                        <div className="row container mb-5">
                            {data?.reason?.map((value, index) => {
                                return (
                                    <div className="col-md-4" key={String(index)}>
                                        <div className="item ps-3 pe-3">
                                            <img src={value?.image} alt={value?.title} />
                                            <strong className="color-orange d-block mb-3 mt-2">{value?.title}</strong>
                                            <div
                                                dangerouslySetInnerHTML={{ __html: value?.content }}
                                                className="mb-5 bold-500"
                                            ></div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </section>

                    <section className="service" id={menu[5]?.id}>
                        <div className="container-fluid wave wave_top">
                            {/* <div className="row"> */}
                            <div className={`col-12 text-center`}>
                                <h2 className="mb-4 color-red">{menu[5]?.name}</h2>
                            </div>
                            {/* </div> */}
                        </div>

                        <div
                            className={`container overflow-scroll flex-md-nowrap`}
                            style={{ paddingTop: width < 600 ? '10vw' : '6.6vw' }}
                        >
                            <div
                                className="border p-5 rounded-5"
                                style={{ minWidth: '900px', margin: width < 600 ? '15px' : 0 }}
                            >
                                <div className="row pb-5 border-bottom">
                                    <div className="col-3 ps-3 pe-3">
                                        <h3 className="color-red">{t('xiaoHongShu.marketingPlatform')}</h3>
                                    </div>
                                    <div className="col-4 ps-3 pe-3">
                                        <h3 className="color-red">{t('xiaoHongShu.trafficGenerationMethod')}</h3>
                                    </div>
                                    <div className="col-5 ps-3">
                                        <h3 className="color-red">{t('xiaoHongShu.platformFeatures')}</h3>
                                    </div>
                                </div>

                                {data?.features?.map((value, index) => {
                                    return (
                                        <div
                                            className={`row pt-5 ${
                                                index < data?.features?.length - 1 ? 'border-bottom pb-5' : ''
                                            }`}
                                            key={String(index)}
                                        >
                                            <div className="col-3 ps-3 pe-3">
                                                {value?.maketing?.map((mar, marI) => {
                                                    return (
                                                        <div
                                                            key={String(marI)}
                                                            className="bold-500 row align-items-center h-auto "
                                                        >
                                                            {mar?.content}
                                                            {mar?.color == 'icon' && (
                                                                <img
                                                                    src={require('~/assets/images/projectsupportstation/images/GigGik_Heart.png')}
                                                                    style={{
                                                                        width: 'auto',
                                                                        height: '30px',
                                                                        marginLeft: '5px',
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <div className="col-4 ps-3 pe-3">
                                                <ul className="check-circle">
                                                    {value?.traffic_generation?.map((traff, traffI) => {
                                                        return (
                                                            <li
                                                                key={String(traffI)}
                                                                className={`${traff?.color} bold-500`}
                                                            >
                                                                {traff?.content}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                            <div className="col-5 ps-3">
                                                <ul className="check-circle">
                                                    {value['platform_features']?.map((platf, platfI) => {
                                                        return (
                                                            <li
                                                                key={String(platfI)}
                                                                className={`${platf?.color} bold-500`}
                                                            >
                                                                {platf?.content}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    );
                                })}

                                <div
                                    style={{
                                        position: 'absolute',
                                        top: width < 600 ? '1vw' : '0vw',
                                        left: width < 600 ? '5vw' : '0vw',
                                        zIndex: 3,
                                    }}
                                >
                                    <img
                                        src={require('~/assets/images/projectsupportstation/images/GigGik_Search.png')}
                                        style={{ width: 'auto', height: width < 600 ? '20vw' : '10vw' }}
                                    />
                                </div>
                            </div>

                            {/* <div className="col-12 border p-5 rounded-5 mobile">
                                <div className="row pb-3 border-bottom">
                                    <div className="col-3 ps-3">
                                        <h3 className="color-red">{t('xiaoHongShu.marketingPlatform')}</h3>
                                    </div>
                                    <div className="col-9 spacing-0 ps-3">
                                        {data?.features?.map((value, index) => {
                                            return (
                                                <div
                                                    className={`col-12 ${
                                                        index < data?.features?.length - 1
                                                            ? 'border-bottom pb-3'
                                                            : 'mt-3 pb-3'
                                                    }`}
                                                    key={String(index)}
                                                >
                                                    {value?.maketing?.map((mar, marI) => {
                                                        return (
                                                            <div className={`col-12 bold-500`} key={String(marI)}>
                                                                <span>
                                                                    {mar?.content}
                                                                    {mar?.color == 'icon' && (
                                                                        <img
                                                                            src={require('~/assets/images/projectsupportstation/images/GigGik_Heart.png')}
                                                                            style={{
                                                                                width: '25px',
                                                                                height: '25px',
                                                                                marginLeft: '10px',
                                                                            }}
                                                                        />
                                                                    )}
                                                                </span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>

                                <div className="row pb-3 border-bottom pt-5">
                                    <div className="col-3 ps-3">
                                        <h3 className="color-red">{t('xiaoHongShu.trafficGenerationMethod')}</h3>
                                    </div>
                                    <div className="col-9 spacing-0 ps-3">
                                        {data?.features?.map((value, index) => {
                                            return (
                                                <div
                                                    className={`col-12 ${
                                                        index < data?.features?.length - 1
                                                            ? 'border-bottom pb-3'
                                                            : 'mt-3'
                                                    }`}
                                                    key={String(index)}
                                                >
                                                    <ul className="check-circle">
                                                        {value?.traffic_generation?.map((traff, traffI) => {
                                                            return (
                                                                <li
                                                                    key={String(traffI)}
                                                                    className={`${traff?.color} bold-500`}
                                                                >
                                                                    {traff?.content}
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>

                                <div className="row pb-3 pt-5">
                                    <div className="col-3 ps-3">
                                        <h3 className="color-red">{t('xiaoHongShu.trafficGenerationMethod')}</h3>
                                    </div>
                                    <div className="col-9 spacing-0 ps-3">
                                        {data?.features?.map((value, index) => {
                                            return (
                                                <div
                                                    className={`col-12 ${
                                                        index < data?.features?.length - 1
                                                            ? 'border-bottom pb-3'
                                                            : 'mt-3'
                                                    }`}
                                                    key={String(index)}
                                                >
                                                    <ul className="check-circle">
                                                        {value['platform_features']?.map((platf, platfI) => {
                                                            return (
                                                                <li
                                                                    key={String(platfI)}
                                                                    className={`${platf?.color} bold-500`}
                                                                >
                                                                    {platf?.content}
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </section>

                    <section className="benefits bg-cream" id={menu[6]?.id}>
                        <div className="container-fluid wave wave_top_cream">
                            <div className="col-12 text-center">
                                <h2 className="mb-4 color-red">{menu[6]?.name}</h2>
                                <p className="font-size-18" style={{ paddingBottom: 0 }}>
                                    {t('xiaoHongShu.oneMonthUpFront')}
                                </p>
                            </div>
                        </div>

                        <div
                            className={`row container mt-5 mb-5 ${
                                width < 600 ? 'justify-content-center' : 'justify-content-between'
                            }`}
                        >
                            {plan?.map((value, index) => {
                                return (
                                    <div
                                        className="relative-container plan mb-5 bold-500"
                                        key={String(index)}
                                        onMouseOver={_onMouseEvent?.bind(this, { index: index, over: true })}
                                        onMouseLeave={_onMouseEvent?.bind(this, { index: index, over: false })}
                                    >
                                        <div className={`col-12 ps-2 pe-2 d-flex h-100 `}>
                                            <div className="col-12 h-100 ">
                                                <div className="border bg-white rounded-6 h-100 ">
                                                    <div
                                                        className={`p-3 text-center rounded-top-6 plan-${value?.color?.trim()}`}
                                                        style={{ height: '10%' }}
                                                    >
                                                        <span className="text-white bold-500">{value?.header}</span>
                                                    </div>
                                                    <div className={`content-plan d-flex flex-column`}>
                                                        <div className="pt-3 pb-3 ps-3 pe-3 text-center">
                                                            <img
                                                                src={value?.image}
                                                                alt={value?.title}
                                                                className="col-8"
                                                            />
                                                        </div>
                                                        <div className="pt-3 pb-3 ps-3 pe-3 text-center">
                                                            <p className="mt-2">{value?.title}</p>
                                                            <div
                                                                className="color-grey"
                                                                dangerouslySetInnerHTML={{ __html: value?.description }}
                                                            />
                                                        </div>

                                                        <img
                                                            src={
                                                                value?.color == 'orange'
                                                                    ? require('~/assets/images/projectsupportstation/images/line_yellow.png')
                                                                    : require('~/assets/images/projectsupportstation/images/line_red.png')
                                                            }
                                                        />

                                                        <div className="pt-3 pb-3 ps-3 pe-3 text-center">
                                                            <div dangerouslySetInnerHTML={{ __html: value?.content }} />
                                                        </div>

                                                        <img
                                                            src={
                                                                value?.color == 'orange'
                                                                    ? require('~/assets/images/projectsupportstation/images/line_yellow.png')
                                                                    : require('~/assets/images/projectsupportstation/images/line_red.png')
                                                            }
                                                        />

                                                        <div className="pt-3 pb-5 ps-3 pe-3 text-center">
                                                            <p
                                                                className={`${
                                                                    value?.color == 'orange'
                                                                        ? 'color-orange'
                                                                        : 'color-red'
                                                                } font-size-18`}
                                                                style={{ marginBottom: 0, padding: 0 }}
                                                            >
                                                                {value?.footer}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="layer rounded-6"
                                            style={
                                                value?.over
                                                    ? { opacity: 0 }
                                                    : value?.over != null
                                                    ? { opacity: 0.3 }
                                                    : { opacity: 0 }
                                            }
                                        ></div>
                                    </div>
                                );
                            })}

                            <div className="col-12 text-center">
                                <a
                                    href="https://sys.giggik.com/index.php?c=Form&a=xhs&source_id=13"
                                    target="_blank"
                                    className="btn btn-default btn-large"
                                >
                                    {t('xiaoHongShu.getQuote')}
                                </a>
                            </div>
                        </div>
                    </section>

                    <section className="service" id={menu[7]?.id} style={{ paddingBottom: '50px' }}>
                        <div className="container-fluid wave wave_top">
                            {/* <div className="row"> */}
                            <div className="col-12 text-center">
                                <h2 className="mb-4 color-red">{menu[7]?.name}</h2>
                            </div>
                            {/* </div> */}
                        </div>

                        <div className="row container mt-5 mb-5" id="accordionFlushUserExample">
                            <div className=" col-lg-4">
                                {data['successful_cases']?.map((value, index) => {
                                    return (
                                        <div style={{ marginBottom: '30px' }} key={String(index)}>
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                // data-bs-toggle="collapse"
                                                // data-bs-target={`#flush-user-collapse${index}`}
                                                // aria-expanded={userIndex == index ? true : false}
                                                // aria-controls={`flush-user-collapse${index}`}
                                                style={{
                                                    backgroundColor: userIndex == index ? '#F47602' : '#FFFBEA',
                                                    borderRadius: 9999,
                                                }}
                                                onClick={setUserIndex?.bind(this, index)}
                                            >
                                                <div className="d-flex row align-items-center ">
                                                    <img
                                                        src={require('~/assets/images/projectsupportstation/images/Gig_Gik_question.png')}
                                                        style={{ width: 'auto', height: '70px', marginRight: '10px' }}
                                                    />
                                                    <p
                                                        className="bold-500"
                                                        style={{
                                                            fontSize: '18px',
                                                            width: 'auto',
                                                            color: userIndex == index ? 'white' : '',
                                                        }}
                                                    >
                                                        {value?.content}
                                                    </p>
                                                </div>
                                                <img
                                                    src={require('~/assets/images/projectsupportstation/images/arrow_next.png')}
                                                    style={{ width: '20px', height: '20px', marginRight: '20px' }}
                                                />
                                            </button>
                                        </div>
                                    );
                                })}
                            </div>

                            <div className="col-lg-8">
                                {data['successful_cases']?.map((value, index) => {
                                    return (
                                        <div
                                            // id={`flush-user-collapse${index}`}
                                            className={`accordion-collapse collapse ${
                                                width < 900 ? '' : 'spacing-start-5'
                                            } ${index == userIndex ? 'show' : ''}`}
                                            // data-bs-parent="#accordionFlushUserExample"
                                            key={String(index)}
                                        >
                                            <img className=" rounded-5 " src={value?.image} alt={value?.content} />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </section>

                    <section className="partial-contact bg-cover py-5" id={menu[8]?.id}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <a
                                        href="https://sys.giggik.com/index.php?c=Form&a=xhs&source_id=13"
                                        target="_blank"
                                        className={`btn btn-default btn-large ${width < 600 ? 'mb-2' : ''}`}
                                    >
                                        {t('projectSupportStation.contactUs')}
                                    </a>
                                    <img
                                        src={require('~/assets/images/projectsupportstation/images/Gig_Gik.png')}
                                        className="Gig_Gik"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="faq" id={menu[9]?.id}>
                        <div className="container-fluid wave wave_bottom">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <h2 className="mb-4 color-red">{menu[9]?.name}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row container content-faq">
                            <div className="col-12">
                                <div className="accordion accordion-flush" id="accordionFlushExample">
                                    {data?.faq?.map((value, index) => {
                                        return (
                                            <div className="accordion-item" key={String(index)}>
                                                <div className="accordion-header" style={{ marginBottom: '30px' }}>
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#flush-collapse${index}`}
                                                        aria-expanded="true"
                                                        aria-controls={`flush-collapse${index}`}
                                                    >
                                                        <img
                                                            src={require('~/assets/images/projectsupportstation/images/Gig_Gik_question.png')}
                                                            className="icon"
                                                        />
                                                        <h3>{value?.category}</h3>
                                                    </button>
                                                </div>

                                                <div
                                                    id={`flush-collapse${index}`}
                                                    className="accordion-collapse collapse ps-5"
                                                    data-bs-parent="#accordionFlushExample"
                                                >
                                                    {value?.question?.map((question, iQuestion) => {
                                                        return (
                                                            <div
                                                                key={String(iQuestion)}
                                                                style={{ marginBottom: '30px' }}
                                                            >
                                                                <button
                                                                    className="accordion-button spacing-0"
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={`#flush-sub-collapse${index}${iQuestion}`}
                                                                    aria-expanded="false"
                                                                    aria-controls={`flush-sub-collapse${index}${iQuestion}`}
                                                                >
                                                                    {/* <img
                                                                        src={require('~/assets/images/projectsupportstation/images/Gig_Gik_question.png')}
                                                                        className="icon"
                                                                    /> */}
                                                                    {question?.question}
                                                                </button>
                                                                <div
                                                                    id={`flush-sub-collapse${index}${iQuestion}`}
                                                                    className="accordion-collapse collapse"
                                                                    data-bs-parent={`#flush-collapse${index}`}
                                                                >
                                                                    <div className="accordion-body">
                                                                        {question?.answer}
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 row mt-3 align-items-center">
                                                                    <div className="col-1 border-bottom-red-3"></div>
                                                                    <div className="col-11 border-bottom"></div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </section>

                    <Footer logo={data?.sticky_logo[0]} iconFooter={iconFooter} />
                </div>
            )}
        </>
    );
};

export default XiaoHongShu;
